define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-description", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = categoryDescription;
  function categoryDescription(category) {
    return (0, _discourseI18n.i18n)(`categories.${category?.slug?.replace(/-/g, "_")}.description`, {
      defaultValue: category?.description
    });
  }
});