define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/override-topic-reply-count", ["exports", "discourse/lib/decorators", "discourse/lib/plugin-api"], function (_exports, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function overrideTopicReplyCount(api) {
    api.modifyClass("model:topic", Superclass => class extends Superclass {
      replyCount() {
        return this.real_reply_count;
      }
      static #_ = (() => dt7948.n(this.prototype, "replyCount", [(0, _decorators.default)("posts_count")]))();
    });
  }
  var _default = _exports.default = {
    name: "override-topic-reply-count",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", overrideTopicReplyCount);
    }
  };
});