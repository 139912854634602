define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/who-can-see-invitees-customization", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.34.0", api => {
    const currentUser = api.getCurrentUser();
    api.registerValueTransformer("discourse-calendar-event-more-menu-should-show-participants", _ref => {
      let {
        value,
        context: {
          event
        }
      } = _ref;
      return value || event.creator.id === currentUser.id;
    });
  });
});