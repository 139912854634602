define("discourse/plugins/discourse-local-guides-connect/discourse/lib/lgc-custom-translations", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This file parses the site setting(s) and adds the correct custom translations (matching
  // the current user's locale) to the default I18n translations.
  //
  // This allows us to use I18n to fetch custom translations (custom keys), and we get string
  // interpolation for free yay!
  //
  // Note if a key is already loaded in I18n, we DO NOT overwrite the value.

  class LgcCustomTranslations {
    static append(siteSettings) {
      try {
        const locale = _discourseI18n.default.currentLocale();
        const customTranslations = this.loadTagsTranslations(locale, siteSettings);
        this.setTranslationsInI18n(locale, customTranslations);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    static loadTagsTranslations(locale, siteSettings) {
      if (siteSettings.lgc_tags_translations) {
        try {
          return JSON.parse(siteSettings.lgc_tags_translations).reduce((result, _ref) => {
            let {
              key,
              translations
            } = _ref;
            if (!key || !translations) {
              return result;
            }
            result[key] = translations.find(t => t.locale === locale)?.translated;
            return result;
          }, {});
        } catch {}
      } else {
        return {};
      }
    }
    static setTranslationsInI18n(locale, translations) {
      for (const [key, value] of Object.entries(translations)) {
        if (_discourseI18n.default.translations[locale].js[`tag_locale_${key}`] === undefined) {
          _discourseI18n.default.translations[locale].js[`tag_locale_${key}`] = value;
        }
      }
    }
  }
  _exports.default = LgcCustomTranslations;
});