define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-above-footer-buttons/meetup-review", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/runloop", "@ember/service", "discourse/components/d-button", "discourse/components/d-menu", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _runloop, _service, _dButton, _dMenu, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MeetupReview extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model
      } = _ref;
      let {
        siteSettings,
        currentUser
      } = _ref2;
      return currentUser.get("groups").any(_ref3 => {
        let {
          name
        } = _ref3;
        return name === siteSettings.meetup_submission_group;
      }) && model.get("isPrivateMessage") && model.get("allowedGroups").any(_ref4 => {
        let {
          name
        } = _ref4;
        return name === siteSettings.meetup_submission_group;
      }) && !model.get("archived") && !model.get("closed") && !model.get("deleted");
    }
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor() {
      super(...arguments);
      this.#hideTopicMap();
      this.#loadRejectionReasons();
      this.#loadRevisionReasons();
    }
    async #loadRejectionReasons() {
      const category = this.siteSettings.meetup_rejection_template_category;
      try {
        const res = await (0, _ajax.ajax)(`/c/${category}/l/latest.json?status=listed`);
        this.rejectionReasons = res.topic_list.topics;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    async #loadRevisionReasons() {
      const category = this.siteSettings.meetup_revision_template_category;
      try {
        const res = await (0, _ajax.ajax)(`/c/${category}/l/latest.json?status=listed`);
        this.revisionReasons = res.topic_list.topics;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    #hideTopicMap() {
      (0, _runloop.next)(() => {
        const topicMap = document.querySelector(".topic-map");
        if (topicMap) {
          topicMap.style.display = "none";
        }
      });
    }
    confirmApprove() {
      this.dialog.confirm({
        message: (0, _discourseI18n.i18n)("meetup_review.confirm_approve"),
        didConfirm: async () => {
          try {
            const {
              topic_id
            } = await (0, _ajax.ajax)(`/t/${this.args.outletArgs.model.id}/approve-meetup`, {
              type: "PUT"
            });
            this.router.transitionTo("topic", topic_id);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        },
        confirmButtonLabel: "meetup_review.confirm_approve_label"
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "confirmApprove", [_object.action]))();
    confirmReject(reason, menu) {
      menu.close();
      this.dialog.confirm({
        message: (0, _discourseI18n.i18n)("meetup_review.confirm_reject", {
          reason: reason.title
        }),
        didConfirm: async () => {
          try {
            await (0, _ajax.ajax)(`/t/${this.args.outletArgs.model.id}/reject-meetup`, {
              type: "PUT",
              data: {
                reason_id: reason.id
              }
            });
            // ensure the closed state is updated
            this.args.outletArgs.model.set("closed", true);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        },
        confirmButtonLabel: "meetup_review.confirm_reject_label"
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "confirmReject", [_object.action]))();
    confirmRevise(reason, menu) {
      menu.close();
      this.dialog.confirm({
        message: (0, _discourseI18n.i18n)("meetup_review.confirm_revise", {
          reason: reason.title
        }),
        didConfirm: async () => {
          try {
            await (0, _ajax.ajax)(`/t/${this.args.outletArgs.model.id}/revise-meetup`, {
              type: "PUT",
              data: {
                reason_id: reason.id
              }
            });
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        },
        confirmButtonLabel: "meetup_review.confirm_revise_label"
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "confirmRevise", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="meetup-review-actions">
          <DButton
            class="btn-default btn-icon-text"
            @action={{this.confirmApprove}}
            @label="meetup_review.approve"
          />
    
          <DMenu
            @identifier="meetup-review-reject"
            @groupIdentifier="meetup-review"
            @label={{i18n "meetup_review.reject"}}
            class="btn-default btn-danger"
            @placement="top"
            as |menu|
          >
            <div class="popup-menu">
              <ul>
                {{#each this.rejectionReasons as |reason|}}
                  <li>
                    <DButton
                      @action={{fn this.confirmReject reason menu}}
                      @translatedLabel={{reason.title}}
                      class="popup-menu-btn"
                    />
                  </li>
                {{/each}}
              </ul>
            </div>
          </DMenu>
    
          <DMenu
            @identifier="meetup-review-revise"
            @groupIdentifier="meetup-review"
            @label={{i18n "meetup_review.revise"}}
            class="btn-default"
            @placement="top"
            as |menu|
          >
            <div class="popup-menu">
              <ul>
                {{#each this.revisionReasons as |reason|}}
                  <li>
                    <DButton
                      @action={{fn this.confirmRevise reason menu}}
                      @translatedLabel={{reason.title}}
                      class="popup-menu-btn"
                    />
                  </li>
                {{/each}}
              </ul>
            </div>
          </DMenu>
        </div>
      
    */
    {
      "id": "B2YeCkkQ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"meetup-review-actions\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-default btn-icon-text\"]],[[\"@action\",\"@label\"],[[30,0,[\"confirmApprove\"]],\"meetup_review.approve\"]],null],[1,\"\\n\\n      \"],[8,[32,1],[[24,0,\"btn-default btn-danger\"]],[[\"@identifier\",\"@groupIdentifier\",\"@label\",\"@placement\"],[\"meetup-review-reject\",\"meetup-review\",[28,[32,2],[\"meetup_review.reject\"],null],\"top\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"popup-menu\"],[12],[1,\"\\n          \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"rejectionReasons\"]]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,0],[[24,0,\"popup-menu-btn\"]],[[\"@action\",\"@translatedLabel\"],[[28,[32,3],[[30,0,[\"confirmReject\"]],[30,2],[30,1]],null],[30,2,[\"title\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[2]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,1],[[24,0,\"btn-default\"]],[[\"@identifier\",\"@groupIdentifier\",\"@label\",\"@placement\"],[\"meetup-review-revise\",\"meetup-review\",[28,[32,2],[\"meetup_review.revise\"],null],\"top\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"popup-menu\"],[12],[1,\"\\n          \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"revisionReasons\"]]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,0],[[24,0,\"popup-menu-btn\"]],[[\"@action\",\"@translatedLabel\"],[[28,[32,3],[[30,0,[\"confirmRevise\"]],[30,4],[30,3]],null],[30,4,[\"title\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"menu\",\"reason\",\"menu\",\"reason\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-above-footer-buttons/meetup-review.js",
      "scope": () => [_dButton.default, _dMenu.default, _discourseI18n.i18n, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MeetupReview;
});