define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/button-customization", ["exports", "@ember/object", "discourse/lib/api", "discourse/plugins/discourse-local-guides-connect/discourse/components/edit-meetup-btn", "discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu-btn"], function (_exports, _object, _api, _editMeetupBtn, _invitePostMenuBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.34.0", api => {
    const currentUser = api.getCurrentUser();
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          buttonKeys,
          firstButtonKey
        }
      } = _ref;
      if (post.user.username === "system") {
        dag.delete(buttonKeys.LIKE);
        dag.delete(buttonKeys.REPLY);
      }
      if (currentUser && !currentUser.staff && post.user.id !== currentUser.id) {
        // only staff(moderators) or the post author can delete posts
        // category moderators cannot delete posts
        dag.delete(buttonKeys.DELETE);
      }
      dag.add("edit-meetup", _editMeetupBtn.default, {
        before: firstButtonKey
      });
      dag.add("invite-post-menu", _invitePostMenuBtn.default, {
        before: [buttonKeys.COPY_LINK, buttonKeys.SHARE],
        after: buttonKeys.LIKE
      });
    });
    api.modifyClass("component:composer-editor", Superclass => class extends Superclass {
      extraButtons(toolbar) {
        if (this.composer.allowUpload && this.composer.uploadIcon) {
          toolbar.addButton({
            id: "upload",
            group: "insertions",
            icon: this.composer.uploadIcon,
            title: "upload",
            sendAction: this.showUploadModal
          });
        }
        toolbar.addButton({
          id: "options",
          group: "extras",
          icon: "gear",
          title: "composer.options",
          sendAction: this.onExpandPopupMenuOptions.bind(this),
          popupMenu: true
        });
      }
      static #_ = (() => dt7948.n(this.prototype, "extraButtons", [_object.action]))();
    });
  });
});