define("discourse/plugins/discourse-local-guides-connect/discourse/components/create-recap", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "truth-helpers", "discourse/models/composer", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/has-meetup-ended", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _truthHelpers, _composer3, _discourseI18n, _hasMeetupEnded, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CreateRecap extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model
      } = _ref;
      let {
        siteSettings
      } = _ref2;
      return model.category_id === parseInt(siteSettings.meetup_category, 10);
    }
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor() {
      super(...arguments);
    }
    get topic() {
      return this.args.outletArgs?.model || this.router.currentRoute.parent?.attributes;
    }
    createRecap() {
      this.composer.open({
        title: `Recap: ${this.topic.title}`,
        categoryId: parseInt(this.siteSettings.meetup_category, 10),
        tags: [this.siteSettings.meetup_recap_tag],
        action: _composer3.default.CREATE_TOPIC,
        draftKey: _composer3.default.NEW_TOPIC_KEY,
        topic: {
          [_constants.EVENT_TOPIC_ID_FIELD]: this.topic.id,
          [_constants.LOCATION_FIELD]: this.topic[_constants.LOCATION_FIELD]
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "createRecap", [_object.action]))();
    get meetupRecapUrl() {
      if (!this.topic.local_guides_event_recap_id) {
        return;
      }
      return `/t/-/${this.topic.local_guides_event_recap_id}`;
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (hasMeetupEnded this.topic.event_starts_at this.topic.event_ends_at)}}
          {{#if this.meetupRecapUrl}}
            <a
              role="button"
              href={{this.meetupRecapUrl}}
              class="g-event__recap view"
            >
              <span>{{i18n "event.view_recap"}}</span>
            </a>
          {{else if
            (or
              this.topic.local_guides_event.is_user_going
              (eq this.currentUser.id this.topic.user_id)
            )
          }}
            <a
              role="button"
              {{on "click" this.createRecap}}
              class="g-event__recap create"
            >
              <span>{{i18n "event.create_recap"}}</span>
            </a>
          {{else}}
            <span class="g-event__concluded">
              {{i18n "event.concluded"}}
            </span>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "cF7na2fu",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"topic\",\"event_starts_at\"]],[30,0,[\"topic\",\"event_ends_at\"]]],null],[[[41,[30,0,[\"meetupRecapUrl\"]],[[[1,\"        \"],[10,3],[14,\"role\",\"button\"],[15,6,[30,0,[\"meetupRecapUrl\"]]],[14,0,\"g-event__recap view\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,1],[\"event.view_recap\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,0,[\"topic\",\"local_guides_event\",\"is_user_going\"]],[28,[32,3],[[30,0,[\"currentUser\",\"id\"]],[30,0,[\"topic\",\"user_id\"]]],null]],null],[[[1,\"        \"],[11,3],[24,\"role\",\"button\"],[24,0,\"g-event__recap create\"],[4,[32,4],[\"click\",[30,0,[\"createRecap\"]]],null],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,1],[\"event.create_recap\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"g-event__concluded\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"event.concluded\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]],[]]]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/create-recap.js",
      "scope": () => [_hasMeetupEnded.default, _discourseI18n.i18n, _truthHelpers.or, _truthHelpers.eq, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CreateRecap;
});