define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/disable-topic-title-override", ["exports", "discourse/lib/api", "discourse/lib/decorators", "discourse/plugins/discourse-local-guides-connect/discourse/lib/meetup-helper"], function (_exports, _api, _decorators, _meetupHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.modifyClass("model:composer", Superclass => class extends Superclass {
      disableTitleInput(editingPost, canEditTopic) {
        return (0, _meetupHelper.isRecapTopic)(this) || editingPost && !canEditTopic;
      }
      static #_ = (() => dt7948.n(this.prototype, "disableTitleInput", [(0, _decorators.default)("editingPost", "topic.details.can_edit")]))();
    });
  });
});