define("discourse/plugins/discourse-local-guides-connect/discourse/components/like-toggle", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/runloop", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse/helpers/number", "discourse/lib/ajax", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _runloop, _service, _truthHelpers, _concatClass, _number, _ajax, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LikeToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "likeCount", [_tracking.tracked], function () {
      return this.args.topic.op_like_count;
    }))();
    #likeCount = (() => (dt7948.i(this, "likeCount"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "liked", [_tracking.tracked], function () {
      return this.args.topic.op_liked || false;
    }))();
    #liked = (() => (dt7948.i(this, "liked"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    clickCounter = 0;
    get canLike() {
      return this.args.topic.op_can_like || false;
    }
    get firstPostId() {
      return this.args.topic.first_post_id || false;
    }
    toggleLikeDebounced() {
      if (this.loading) {
        return;
      }
      this.clickCounter++;
      this.liked = !this.liked;
      this.likeCount += this.liked ? 1 : -1;
      (0, _runloop.debounce)(this, this.performToggleLike, 1000); // 1s delay
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleLikeDebounced", [_object.action]))();
    async performToggleLike() {
      if (this.clickCounter % 2 === 0) {
        this.clickCounter = 0;
        return;
      }
      this.loading = true;
      try {
        if (this.firstPostId) {
          if (!this.liked) {
            await (0, _ajax.ajax)(`/post_actions/${this.firstPostId}`, {
              type: "DELETE",
              data: {
                post_action_type_id: 2
              }
            });
          } else {
            await (0, _ajax.ajax)(`/post_actions`, {
              type: "POST",
              data: {
                id: this.firstPostId,
                post_action_type_id: 2
              }
            });
          }
        }
      } catch {
        // Rollback UI changes in case of an error
        this.liked = !this.liked;
        this.likeCount += this.liked ? 1 : -1;
        this.dialog.alert(this.liked ? "Sorry, you can't remove that like. Please refresh." : "Sorry, you can't like that topic.");
      } finally {
        this.loading = false;
        this.clickCounter = 0;
      }
    }
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <button
          {{on "click" this.toggleLikeDebounced}}
          type="button"
          disabled={{or (not this.canLike) @topic.archived}}
          title={{if (this.canLike) (i18n "post.like") (i18n "post.like_disabled")}}
          class={{concatClass
            (if this.liked "g-topic__like-button--liked")
            "g-topic__like-button"
          }}
        >
          {{#if this.likeCount}}
            {{number this.likeCount}}
          {{else}}
            <span>
              {{i18n "post.like"}}
            </span>
          {{/if}}
        </button>
      
    */
    {
      "id": "JAYhKODq",
      "block": "[[[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[28,[32,0],[[28,[32,1],[[30,0,[\"canLike\"]]],null],[30,1,[\"archived\"]]],null]],[16,\"title\",[52,[28,[30,0,[\"canLike\"]],null,null],[28,[32,2],[\"post.like\"],null],[28,[32,2],[\"post.like_disabled\"],null]]],[16,0,[28,[32,3],[[52,[30,0,[\"liked\"]],\"g-topic__like-button--liked\"],\"g-topic__like-button\"],null]],[24,4,\"button\"],[4,[32,4],[\"click\",[30,0,[\"toggleLikeDebounced\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"likeCount\"]],[[[1,\"        \"],[1,[28,[32,5],[[30,0,[\"likeCount\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"post.like\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/like-toggle.js",
      "scope": () => [_truthHelpers.or, _truthHelpers.not, _discourseI18n.i18n, _concatClass.default, _modifier.on, _number.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LikeToggle;
});