define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-not-going-button/no-button", ["exports", "discourse/components/d-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NoButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      @label="event.going_no"
      @action={{@outletArgs.markAsNotGoing}}
      class="not-going-button"
    />
  
  */
  {
    "id": "qcRItEYi",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"not-going-button\"]],[[\"@label\",\"@action\"],[\"event.going_no\",[30,1,[\"markAsNotGoing\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-not-going-button/no-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "no-button:NoButton"));
  var _default = _exports.default = NoButton;
});