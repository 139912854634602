define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-buttons/event-status-label", ["exports", "@glimmer/component", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventStatusLabel extends _component.default {
    get label() {
      return (0, _discourseI18n.i18n)("event.going");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="event-status__label">{{this.label}}</span>
        <div class="event-status__buttons">
          {{yield}}
        </div>
      
    */
    {
      "id": "UgMsZVP9",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"event-status__label\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"event-status__buttons\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-buttons/event-status-label.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventStatusLabel;
});