define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/above-user-profile/title", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/helpers/body-class", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _bodyClass, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PageHeading extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectedPreferencesItem", [_tracking.tracked], function () {
      return this.preferencesItems.find(item => this.router.currentRouteName.includes(item.route)).label || this.preferencesItems[0]?.label;
    }))();
    #selectedPreferencesItem = (() => (dt7948.i(this, "selectedPreferencesItem"), void 0))();
    preferencesItems = (() => [{
      route: "preferences.account",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.account")
    }, {
      route: "preferences.security",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.security")
    }, {
      route: "preferences.profile",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.profile")
    }, {
      route: "preferences.emails",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.emails")
    }, {
      route: "preferences.notifications",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.notifications")
    }, {
      route: "preferences.tracking",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.tracking")
    }, {
      route: "preferences.users",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.users")
    }, {
      route: "preferences.interface",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.interface")
    }, {
      route: "preferences.navigation-menu",
      label: (0, _discourseI18n.i18n)("user.preferences_nav.navigation_menu")
    }])();
    selectPreferencesItem(preferencesItem) {
      this.selectedPreferencesItem = preferencesItem;
      this.router.transitionTo(preferencesItem);
    }
    static #_3 = (() => dt7948.n(this.prototype, "selectPreferencesItem", [_object.action]))();
    get isMessages() {
      return this.router.currentRouteName.includes("userPrivateMessages");
    }
    get isBookmarks() {
      return this.router.currentRouteName === "userActivity.bookmarks";
    }
    get isFeed() {
      return this.router.currentRoute.parent.name === "follow";
    }
    get isPreferences() {
      return this.router.currentRouteName.includes("preferences");
    }
    get isMeetupSubmissions() {
      return this.router.currentURL.includes("/messages/group/meetup-submissions");
    }
    get isDrafts() {
      return this.router.currentRouteName === "userActivity.drafts";
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.isDrafts}}
          {{bodyClass "user-activity-drafts-page"}}
        {{else if this.isMeetupSubmissions}}
          {{bodyClass "meetup-submissions"}}
        {{/if}}
    
        {{#if this.isMessages}}
          <div class="g-profile__title">
            <h3>
              {{i18n "js.user.private_messages"}}
            </h3>
          </div>
        {{/if}}
    
        {{#if this.isBookmarks}}
          <div class="g-profile__title">
            <h3>
              {{i18n "js.user.bookmarks"}}
            </h3>
          </div>
        {{/if}}
    
        {{#if this.isPreferences}}
          <div class="g-profile__title">
            <h3>
              {{i18n "js.user.preferences.title"}}
            </h3>
          </div>
          <div class="g-profile__navigation">
            <ComboBox
              @value={{this.selectedPreferencesItem}}
              @content={{this.preferencesItems}}
              @onChange={{this.selectPreferencesItem}}
              @nameProperty="label"
              @valueProperty="route"
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "HvdZMIqR",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isDrafts\"]],[[[1,\"      \"],[1,[28,[32,0],[\"user-activity-drafts-page\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isMeetupSubmissions\"]],[[[1,\"      \"],[1,[28,[32,0],[\"meetup-submissions\"],null]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"\\n\"],[41,[30,0,[\"isMessages\"]],[[[1,\"      \"],[10,0],[14,0,\"g-profile__title\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"js.user.private_messages\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isBookmarks\"]],[[[1,\"      \"],[10,0],[14,0,\"g-profile__title\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"js.user.bookmarks\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isPreferences\"]],[[[1,\"      \"],[10,0],[14,0,\"g-profile__title\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"js.user.preferences.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"g-profile__navigation\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@value\",\"@content\",\"@onChange\",\"@nameProperty\",\"@valueProperty\"],[[30,0,[\"selectedPreferencesItem\"]],[30,0,[\"preferencesItems\"]],[30,0,[\"selectPreferencesItem\"]],\"label\",\"route\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/above-user-profile/title.js",
      "scope": () => [_bodyClass.default, _discourseI18n.i18n, _comboBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PageHeading;
});