define("discourse/plugins/discourse-local-guides-connect/discourse/lib/custom-combo-box", ["exports", "select-kit/components/combo-box"], function (_exports, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomComboBox extends _comboBox.default {
    search(filter) {
      if (filter) {
        filter = this._normalize(filter);
        const nameContent = this.content.filter(c => {
          const name = this._normalize(this.getName(c));
          return name?.startsWith(filter);
        }) || [];
        let valueContent = [];
        if (filter.length === 2) {
          const value = this.content.find(i => i["value"] === filter.toUpperCase());
          valueContent = [value];
        }
        return [...valueContent, ...nameContent];
      } else {
        return this.content;
      }
    }
  }
  _exports.default = CustomComboBox;
});