define("discourse/plugins/discourse-local-guides-connect/discourse/components/tags-intersection-chooser", ["exports", "@ember-decorators/component", "discourse/lib/helpers", "discourse-i18n", "select-kit/components/mini-tag-chooser", "select-kit/components/select-kit"], function (_exports, _component, _helpers, _discourseI18n, _miniTagChooser, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TagsIntersectionChooser = dt7948.c(class TagsIntersectionChooser extends _miniTagChooser.default {
    mainTag = null;
    additionalTags = null;
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set("value", (0, _helpers.makeArray)(this.mainTag).concat((0, _helpers.makeArray)(this.additionalTags)));
    }
    modifyNoSelection() {
      return this.defaultItem(null, (0, _discourseI18n.i18n)(this.selectKit.options.none ?? "tagging.choose_for_topic"));
    }
  }, [(0, _component.classNames)("tags-intersection-chooser"), (0, _component.attributeBindings)("selectKit.options.categoryId:category-id"), (0, _selectKit.selectKitOptions)({
    closeOnChange: true
  }), (0, _selectKit.pluginApiIdentifiers)("tags-intersection-chooser")]);
  var _default = _exports.default = TagsIntersectionChooser;
});