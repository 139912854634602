define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/post-edits", ["exports", "discourse/lib/api", "discourse/lib/formatter", "discourse-i18n"], function (_exports, _api, _formatter, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.historyHeat = historyHeat;
  function mult(val) {
    return 60 * 50 * 1000 * val;
  }
  function historyHeat(siteSettings, updatedAt) {
    if (!updatedAt) {
      return;
    }

    // Show heat on age
    const rightNow = Date.now();
    const updatedAtTime = updatedAt.getTime();
    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_low)) {
      return "heatmap-high";
    }
    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_medium)) {
      return "heatmap-med";
    }
    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_high)) {
      return "heatmap-low";
    }
  }
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.reopenWidget("post-edits-indicator", {
      tagName: "div.post-info.edits",
      html(attrs) {
        let icon = "pencil";
        const updatedAt = new Date(attrs.updated_at);
        let className = historyHeat(this.siteSettings, updatedAt);
        const date = (0, _formatter.longDate)(updatedAt);
        let title;
        if (attrs.wiki) {
          icon = "far-pen-to-square";
          className = `${className || ""} wiki`.trim();
          if (attrs.version > 1) {
            title = (0, _discourseI18n.i18n)("post.wiki_last_edited_on", {
              dateTime: date
            });
          } else {
            title = (0, _discourseI18n.i18n)("post.wiki.about");
          }
        } else {
          title = (0, _discourseI18n.i18n)("post.last_edited_on", {
            dateTime: date
          });
        }
        return this.attach("flat-button", {
          icon,
          translatedTitle: title,
          className,
          data: {
            content: (0, _discourseI18n.i18n)("post.edited")
          },
          action: "onPostEditsIndicatorClick",
          translatedAriaLabel: (0, _discourseI18n.i18n)("post.edit_history"),
          translatedLabel: attrs.version > 1 ? attrs.version - 1 : ""
        });
      },
      onPostEditsIndicatorClick() {
        if (this.attrs.wiki && this.attrs.version === 1) {
          this.sendWidgetAction("editPost");
        } else if (this.attrs.canViewEditHistory) {
          this.sendWidgetAction("showHistory");
        }
      }
    });
  });
});