define("discourse/plugins/discourse-local-guides-connect/discourse/components/user-custom-fields", ["exports", "@glimmer/component", "@ember/component", "@ember/helper", "@ember/object", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", "discourse/plugins/discourse-local-guides-connect/discourse/components/location-field", "discourse/plugins/discourse-local-guides-connect/discourse/components/timezone-input", "@ember/template-factory"], function (_exports, _component, _component2, _helper, _object, _discourseI18n, _constants, _locationField, _timezoneInput, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserCustomFields extends _component.default {
    get location() {
      return this.args.outletArgs.model.custom_fields[_constants.LOCATION_FIELD];
    }
    get isGMapsLinkPublic() {
      return this.args.outletArgs.model.custom_fields[_constants.IS_GMAPS_LINK_PUBLIC_FIELD];
    }
    set isGMapsLinkPublic(value) {
      this.args.outletArgs.model.custom_fields[_constants.IS_GMAPS_LINK_PUBLIC_FIELD] = value;
    }
    setLocation(location) {
      this.args.outletArgs.model.custom_fields[_constants.LOCATION_FIELD] = location;
    }
    static #_ = (() => dt7948.n(this.prototype, "setLocation", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group">
          <label class="control-label">
            {{i18n "user.location"}}
          </label>
    
          <LocationField
            @value={{this.location.address}}
            @onChange={{this.setLocation}}
            @citiesOnly={{true}}
            placeholder={{i18n "user_preferences.location.placeholder"}}
          />
    
          <div class="instructions">
            {{i18n "user_preferences.location.instructions"}}
          </div>
        </div>
    
        <div class="control-group">
          <label class="control-label">
            {{i18n "js.user.timezone"}}
          </label>
    
          <TimezoneInput
            @value={{@outletArgs.model.timezone}}
            @onChange={{fn (mut @outletArgs.model.timezone)}}
            class="input-xxlarge"
          />
        </div>
    
        <div class="control-group">
          <label class="control-label checkbox-label">
            <Input @checked={{this.isGMapsLinkPublic}} @type="checkbox" />
            <span>
              {{i18n "user_preferences.is_gmaps_link_public.label"}}
            </span>
          </label>
        </div>
      
    */
    {
      "id": "jwtJqrqj",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user.location\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,1],[[16,\"placeholder\",[28,[32,0],[\"user_preferences.location.placeholder\"],null]]],[[\"@value\",\"@onChange\",\"@citiesOnly\"],[[30,0,[\"location\",\"address\"]],[30,0,[\"setLocation\"]],true]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user_preferences.location.instructions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"js.user.timezone\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,2],[[24,0,\"input-xxlarge\"]],[[\"@value\",\"@onChange\"],[[30,1,[\"model\",\"timezone\"]],[28,[32,3],[[28,[31,0],[[30,1,[\"model\",\"timezone\"]]],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label checkbox-label\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@checked\",\"@type\"],[[30,0,[\"isGMapsLinkPublic\"]],\"checkbox\"]],null],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"user_preferences.is_gmaps_link_public.label\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/user-custom-fields.js",
      "scope": () => [_discourseI18n.i18n, _locationField.default, _timezoneInput.default, _helper.fn, _component2.Input],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserCustomFields;
});