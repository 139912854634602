define("discourse/plugins/discourse-local-guides-connect/discourse/components/modal/flag-confirmation-dialog", ["exports", "discourse/components/d-button", "discourse/components/d-modal", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _dModal, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FlagConfirmationDialog = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal class="g-dialog --flagged">
      <:body>
        <i>check_circle</i>
        <h1>{{i18n "dialog.flagged.title"}}</h1>
        <span>{{i18n "dialog.flagged.description"}}</span>
      </:body>
      <:footer>
        <DButton
          class="g-button--secondary"
          @action={{@closeModal}}
          @label="dialog.done"
        />
      </:footer>
    </DModal>
  
  */
  {
    "id": "GlKr/Lpg",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"g-dialog --flagged\"]],null,[[\"body\",\"footer\"],[[[[1,\"\\n      \"],[10,\"i\"],[12],[1,\"check_circle\"],[13],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[32,1],[\"dialog.flagged.title\"],null]],[13],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,1],[\"dialog.flagged.description\"],null]],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[32,2],[[24,0,\"g-button--secondary\"]],[[\"@action\",\"@label\"],[[30,1],\"dialog.done\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/modal/flag-confirmation-dialog.js",
    "scope": () => [_dModal.default, _discourseI18n.i18n, _dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "flag-confirmation-dialog:FlagConfirmationDialog"));
  var _default = _exports.default = FlagConfirmationDialog;
});