define("discourse/plugins/discourse-local-guides-connect/discourse/components/modal/edit-pinned-carousel-topics-modal", ["exports", "@glimmer/component", "discourse/components/async-content", "discourse/components/d-modal", "discourse/lib/ajax", "admin/components/site-setting", "admin/models/site-setting", "@ember/component", "@ember/template-factory"], function (_exports, _component, _asyncContent, _dModal, _ajax, _siteSetting, _siteSetting2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocalListSetting extends _siteSetting.default {
    async _save() {
      const setting = this.buffered;
      const name = setting.get("setting");
      const value = setting.get("value");
      await (0, _ajax.ajax)(`/${name}-setting.json`, {
        type: "PUT",
        data: {
          topic_ids: value
        }
      });
      document.location.reload();
    }
  }
  class EditPinnedCarouselTopicsModal extends _component.default {
    async loadSetting(settingName) {
      const data = await (0, _ajax.ajax)(`/${settingName}-setting.json`);
      return _siteSetting2.default.create(data.site_setting);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal class="g-modal" @closeModal={{@closeModal}}>
          <:body>
            <AsyncContent @asyncData={{this.loadSetting @model.setting}}>
              <:content as |setting|>
                <LocalListSetting @setting={{setting}} />
              </:content>
            </AsyncContent>
          </:body>
        </DModal>
      
    */
    {
      "id": "+bZj2PLW",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"g-modal\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@asyncData\"],[[28,[30,0,[\"loadSetting\"]],[[30,2,[\"setting\"]]],null]]],[[\"content\"],[[[[1,\"\\n            \"],[8,[32,2],null,[[\"@setting\"],[[30,3]]],null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\",\"setting\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/modal/edit-pinned-carousel-topics-modal.js",
      "scope": () => [_dModal.default, _asyncContent.default, LocalListSetting],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EditPinnedCarouselTopicsModal;
});