define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/extra-nav-item/custom-nav-pills", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomNavPills extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    get currentCategory() {
      this.discovery.category;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if
          (and
            this.currentUser.staff
            (eq this.router.currentRouteName "discovery.latest")
          )
        }}
          <li class="nav-item_unlisted">
            <a href="/latest?q=status%3Aunlisted">
              {{i18n "js.composer.unlist"}}
            </a>
          </li>
          <li class="nav-item_archived">
            <a href="/latest?q=status%3Aarchived">
              {{i18n "js.chat.browse.filter_archived"}}
            </a>
          </li>
          <li class="nav-item_deleted">
            <a href="/latest?q=status%3Adeleted">
              {{i18n "js.chat.deleted_chat_username"}}
            </a>
          </li>
        {{/if}}
      
    */
    {
      "id": "mvxoCssO",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"currentUser\",\"staff\"]],[28,[32,1],[[30,0,[\"router\",\"currentRouteName\"]],\"discovery.latest\"],null]],null],[[[1,\"      \"],[10,\"li\"],[14,0,\"nav-item_unlisted\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"/latest?q=status%3Aunlisted\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"js.composer.unlist\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item_archived\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"/latest?q=status%3Aarchived\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"js.chat.browse.filter_archived\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item_deleted\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"/latest?q=status%3Adeleted\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"js.chat.deleted_chat_username\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/extra-nav-item/custom-nav-pills.js",
      "scope": () => [_truthHelpers.and, _truthHelpers.eq, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomNavPills;
});