define("discourse/plugins/discourse-local-guides-connect/discourse/services/featured-guidelines-tag", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/topic-list"], function (_exports, _service, _ajax, _ajaxError, _topicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeaturedGuidelinesTagService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    maxFeaturedTopics = (() => this.siteSettings.lgc_featured_topics_max ?? 12)();
    get isInGuidelines() {
      return this.discovery.category?.slug === this.siteSettings.local_guides_connect_guidelines_category_slug;
    }
    async loadTopics() {
      const {
        featuredTopics,
        shouldLoadMore
      } = await this._loadPinnedTopics();
      if (!shouldLoadMore) {
        return featuredTopics;
      }
      const topicsTaggedAsFeaturedGuidelines = await this._loadFeaturedTopics({
        excluding: featuredTopics
      });
      return featuredTopics.concat(topicsTaggedAsFeaturedGuidelines);
    }
    async _loadPinnedTopics() {
      if (!this.siteSettings.local_guides_connect_featured_guidelines_topics) {
        return {
          featuredTopics: [],
          shouldLoadMore: true
        };
      }
      const pinnedTopics = await (0, _ajax.ajax)("/community-guidelines-carrousel-pinned.json").catch(_ajaxError.popupAjaxError);
      const featuredTopics = _topicList.default.topicsFrom(this.store, pinnedTopics).slice(0, this.maxFeaturedTopics);
      return {
        featuredTopics,
        shouldLoadMore: featuredTopics.length < this.maxFeaturedTopics
      };
    }
    async _loadFeaturedTopics(_ref) {
      let {
        excluding: alreadyLoadedTopics
      } = _ref;
      const featuredTopics = await (0, _ajax.ajax)("/tag/featured-guidelines.json").catch(e => {
        if (e.jqXHR.status === 404) {
          // the featured tag has not been set up
          return;
        }
        (0, _ajaxError.popupAjaxError)(e);
      });
      return _topicList.default.topicsFrom(this.store, featuredTopics).filter(topic => {
        return !alreadyLoadedTopics.any(t => t.id === topic.id); // remove duplicates
      }).slice(0, this.maxFeaturedTopics - alreadyLoadedTopics.length);
    }
  }
  _exports.default = FeaturedGuidelinesTagService;
});