define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/after-panel-body/notifications-header", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AfterPanelBodyNotificationsHeader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "header", [_service.service]))();
    #header = (() => (dt7948.i(this, "header"), void 0))();
    closeUserMenu() {
      this.header.userVisible = false;
    }
    static #_2 = (() => dt7948.n(this.prototype, "closeUserMenu", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="notifications__heading">
          <DButton
            class="btn btn-transparent notifications__back"
            @action={{this.closeUserMenu}}
            aria-label="Close notifications panel"
          />
          <h3>{{i18n "js.user.notifications"}}</h3>
        </div>
      
    */
    {
      "id": "JyVu1NsS",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"notifications__heading\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn btn-transparent notifications__back\"],[24,\"aria-label\",\"Close notifications panel\"]],[[\"@action\"],[[30,0,[\"closeUserMenu\"]]]],null],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,1],[\"js.user.notifications\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/after-panel-body/notifications-header.js",
      "scope": () => [_dButton.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AfterPanelBodyNotificationsHeader;
});