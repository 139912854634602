define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/localize-tags", ["exports", "handlebars", "discourse/lib/escape", "discourse/lib/get-url", "discourse/lib/helpers", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _handlebars, _escape, _getUrl, _helpers, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function localizeTags(tag, params) {
    let {
      siteSettings,
      currentUser
    } = (0, _helpers.helperContext)();
    params = params || {};
    let visibleName = _handlebars.default.Utils.escapeExpression(tag);
    tag = visibleName.toLowerCase();
    const classes = ["discourse-tag"];
    const tagName = params.tagName || "a";
    let path;
    if (tagName === "a" && !params.noHref) {
      if ((params.isPrivateMessage || params.pmOnly) && currentUser) {
        const username = params.tagsForUser ? params.tagsForUser : currentUser.username;
        path = `/u/${username}/messages/tags/${tag}`;
      } else {
        if (tag === siteSettings.meetup_recap_tag.toLowerCase()) {
          const meetupCategory = parseInt(siteSettings.meetup_category, 10);
          path = `/c/${meetupCategory}/l/latest?q=in:recaps`;
        } else {
          path = `/tag/${tag}`;
        }
      }
    }
    const href = path ? ` href='${(0, _getUrl.default)(path)}' ` : "";
    if (siteSettings.tag_style || params.style) {
      classes.push(params.style || siteSettings.tag_style);
    }
    if (params.extraClass) {
      classes.push(params.extraClass);
    }
    if (params.size) {
      classes.push(params.size);
    }
    // hide the tag if it's a country tag (US, UK, CA, etc)
    if (tag.length === 2) {
      classes.push("hidden");
    }
    const tagDescription = params.description && params.description.replace(/<.+?>/g, "");
    visibleName = (0, _discourseI18n.i18n)(`tag_locale_${tag}`, {
      defaultValue: tagDescription ? tagDescription : visibleName
    });
    let val = "<" + tagName + href + " data-tag-name=" + tag + ' title="' + (0, _escape.default)(tagDescription) + '" ' + " class='" + classes.join(" ") + "'>" + visibleName;
    "</" + tagName + ">";
    if (params.count) {
      val += " <span class='discourse-tag-count'>" + params.count + "</span>";
    }
    return val;
  }
  var _default = _exports.default = {
    name: "localize-tags",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        api.replaceTagRenderer(localizeTags);
      });
    }
  };
});