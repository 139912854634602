define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/custom-homepage/homepage", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/helpers/body-class", "discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/banner", "discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/carousel", "discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/categories", "discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _service, _bodyClass, _banner, _carousel, _categories, _latest, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Homepage extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "featuredTag", [_service.service]))();
    #featuredTag = (() => (dt7948.i(this, "featuredTag"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "featuredTopics", [_tracking.tracked], function () {
      return [];
    }))();
    #featuredTopics = (() => (dt7948.i(this, "featuredTopics"), void 0))();
    async loadFeaturedTopics() {
      this.featuredTopics = await this.featuredTag.loadTopics();
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadFeaturedTopics", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{bodyClass "homepage"}}
    
        <div class="g-home" {{didInsert this.loadFeaturedTopics}}>
          <HomepageBanner />
    
          {{#if this.featuredTopics.length}}
            <HomepageCarousel @topics={{this.featuredTopics}} />
          {{/if}}
    
          <div class="g-home__content">
            <HomepageCategories />
            <HomepageLatest />
          </div>
        </div>
      
    */
    {
      "id": "GsT5w0xQ",
      "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[\"homepage\"],null]],[1,\"\\n\\n    \"],[11,0],[24,0,\"g-home\"],[4,[32,1],[[30,0,[\"loadFeaturedTopics\"]]],null],[12],[1,\"\\n      \"],[8,[32,2],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"featuredTopics\",\"length\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@topics\"],[[30,0,[\"featuredTopics\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"g-home__content\"],[12],[1,\"\\n        \"],[8,[32,4],null,null,null],[1,\"\\n        \"],[8,[32,5],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/custom-homepage/homepage.js",
      "scope": () => [_bodyClass.default, _didInsert.default, _banner.default, _carousel.default, _categories.default, _latest.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Homepage;
});