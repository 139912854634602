define("discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu-btn", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse/components/modal/create-invite", "discourse/lib/get-url", "discourse/models/composer", "discourse-i18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dropdownMenu, _createInvite, _getUrl, _composer3, _discourseI18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InvitePostMenuBtn extends _component.default {
    static shouldRender(args) {
      const eventDetails = args.post.topic?.postStream?.posts[0]?.event;
      const eventDate = eventDetails?.ends_at || eventDetails?.starts_at;
      return args.post.firstPost && eventDate && !moment(eventDate).isBefore(moment());
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    openInviteModal() {
      const topic = this.args.post.topic;
      if (!topic) {
        this.dialog.alert((0, _discourseI18n.i18n)("generic_error_with_reason", {
          error: "Topic data not found."
        }));
        return;
      }
      this.modal.show(_createInvite.default, {
        model: {
          inviteToTopic: true,
          topics: [topic],
          topicId: topic.id,
          topicTitle: topic.title
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "openInviteModal", [_object.action]))();
    startPrivateMessage() {
      const topic = this.args.post.topic;
      if (!topic) {
        this.dialog.alert((0, _discourseI18n.i18n)("generic_error_with_reason", {
          error: "Topic data not found."
        }));
        return;
      }
      this.composer.open({
        action: _composer3.default.PRIVATE_MESSAGE,
        recipients: "",
        topicTitle: `${(0, _discourseI18n.i18n)("js.composer.meetup_invitation.title")} ${topic.title}`,
        topicBody: `${(0, _getUrl.getAbsoluteURL)(topic.url)}/1`,
        archetypeId: "private_message",
        tags: [],
        draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "startPrivateMessage", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          class="g-invite-button-post"
          ...attributes
          @autofocus={{true}}
          @identifier="invite-post-menu"
          @icon="user-plus"
          @modalForMobile={{false}}
          @title={{i18n "js.topic.share.invite_users"}}
        >
          <DropdownMenu as |dropdown|>
            <dropdown.item>
              <DButton
                @label="js.topic.invite_by_username"
                @icon="plus-user"
                class="btn-flat g-invite-menu__username"
                @action={{this.startPrivateMessage}}
              />
            </dropdown.item>
    
            <dropdown.item>
              <DButton
                @label="js.topic.invite_by_email"
                @icon="plus-user"
                class="btn-flat g-invite-menu__email"
                @action={{this.openInviteModal}}
              />
            </dropdown.item>
          </DropdownMenu>
        </DMenu>
      
    */
    {
      "id": "gJchsLxW",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"g-invite-button-post\"],[17,1]],[[\"@autofocus\",\"@identifier\",\"@icon\",\"@modalForMobile\",\"@title\"],[true,\"invite-post-menu\",\"user-plus\",false,[28,[32,1],[\"js.topic.share.invite_users\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],[[24,0,\"btn-flat g-invite-menu__username\"]],[[\"@label\",\"@icon\",\"@action\"],[\"js.topic.invite_by_username\",\"plus-user\",[30,0,[\"startPrivateMessage\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],[[24,0,\"btn-flat g-invite-menu__email\"]],[[\"@label\",\"@icon\",\"@action\"],[\"js.topic.invite_by_email\",\"plus-user\",[30,0,[\"openInviteModal\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"dropdown\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu-btn.js",
      "scope": () => [_dMenu.default, _discourseI18n.i18n, _dropdownMenu.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = InvitePostMenuBtn;
});