define("discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-event-type", ["exports", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/chips", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseI18n, _chips, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_TYPES = ["virtual", "in_person", "hybrid"];
  const eventTypesWithNames = EVENT_TYPES.map(id => ({
    id,
    name: (0, _discourseI18n.i18n)(`meetup_form.type_field.${id}`)
  }));
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <Chips
      @items={{eventTypesWithNames}}
      @selected={{@value}}
      @onSelect={{@onChange}}
      @max={{1}}
    />
  
  */
  {
    "id": "r/uMVJrz",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@items\",\"@selected\",\"@onSelect\",\"@max\"],[[32,1],[30,1],[30,2],1]],null],[1,\"\\n\"]],[\"@value\",\"@onChange\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-event-type.js",
    "scope": () => [_chips.default, eventTypesWithNames],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "select-event-type"));
});